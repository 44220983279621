import React, { useState, useEffect, useRef } from 'react';
import Peer from 'simple-peer';
import styled from 'styled-components';
import { Col, Divider, Row, Card, List, Badge, Breadcrumb, Layout, Menu, message } from 'antd';
import { useHistory, useParams } from "react-router-dom";

import { WebRTCStats } from '@peermetrics/webrtc-stats'


import 'antd/dist/antd.min.css';
import './Meet.css'

import socket from '../../socket';
import VideoCard from '../Video/VideoCard';
import BottomBar from '../BottomBar/BottomBar';
import Chat from '../Chat/Chat';

// => here,need to dig more into this
import * as process from 'process';
import { AudioVisu } from '../AudioVisualizer/AudioVisu';

(window).global = window;
(window).process = process;
(window).Buffer = [];
RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection;

// <=

const Meet = (props) => {
  let history = useHistory();
  const currentUser = sessionStorage.getItem('user');
  const [peers, setPeers] = useState([]);
  const [userVideoAudio, setUserVideoAudio] = useState({
    localUser: { video: true, audio: true },
  });
  const [videoDevices, setVideoDevices] = useState([]);
  const [displayChat, setDisplayChat] = useState(false);
  const [screenShare, setScreenShare] = useState(false);
  const [showVideoDevices, setShowVideoDevices] = useState(false);
  const peersRef = useRef([]);
  const userVideoRef = useRef();
  const screenTrackRef = useRef();
  const userStream = useRef();
  const connectingRef = useRef();
  const roomId = props.match.params.roomId;

  const [statsReport, setStatsReport] = useState({})
  const [time, setTime] = useState(Date.now());

  const { Header, Content, Footer } = Layout;

  // nc
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState(0);

  //nc
  const role = sessionStorage.getItem('role');

  const [musicianPeers, setMusicianPeers] = useState([]);
  const [spectatorPeers, setSpectatorPeers] = useState([]);
  const startCounter = role === "Spectator" ? 1 : 2;


  useEffect(() => {

    console.log("role : ", role);
    const __ = role === "Spectator" ? connectingRef?.current.click() : null

    const _ = sessionStorage.getItem('user') === null ? props.history.push("/", { roomId }) : setToStart()

    const statsIntervals = setInterval(() => {

      if (peersRef.current.length) {
        peersRef.current.forEach(({ peer }, index) => {
          let peerStats = {};
          if (role === "Musician") {
            if (peer?.role === "Musician") {
              peer?.getStats((err, stats) => {
                stats.forEach((stats_report) => {
                  if (stats_report.kind === "video" && stats_report.type === "remote-inbound-rtp") {
                    peerStats['rtt'] = stats_report.roundTripTime
                    const newOne = { [peer.userName]: peerStats };
                    setStatsReport(pre_reports => ({ ...pre_reports, ...newOne }))
                  }
                  else if (stats_report.kind === "video" && stats_report.framesPerSecond) {
                    peerStats['fps'] = stats_report.framesPerSecond
                    const newOne = { [peer.userName]: peerStats };
                    setStatsReport(pre_reports => ({ ...pre_reports, ...newOne }))
                  }
                })
              })
            }
          }
          else {
            if (peer?.role === "Musician") {
              peer?.getStats((err, stats) => {

                stats.forEach((stats_report) => {
                  if (stats_report.type === "candidate-pair") {
                    peerStats['rtt'] = stats_report.currentRoundTripTime
                    const newOne = { [peer.userName]: peerStats };
                    setStatsReport(pre_reports => ({ ...pre_reports, ...newOne }))
                  }
                })
              })
            }
          }
        })
      }
    }, 2000);

    return () => {
      socket.disconnect();
      clearInterval(statsIntervals);
      // alert("test");
    };

  }, []);

  useEffect(() => {
    console.log("peers modified :", peers);
  }, [peers]);

  useEffect(() => {
    console.log("userVideoAudio modified :", userVideoAudio);
  }, [userVideoAudio]);


  // if (window.performance) {
  // }
  const getSetDevices = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // nc
      const audioDevices = devices.filter((adevice) => adevice.kind === 'audioinput');
      const videoDevices = devices.filter((vdevice) => vdevice.kind === 'videoinput');
      setAudioDevices(audioDevices);
      setVideoDevices(videoDevices);
      setSelectedAudioDeviceId(audioDevices[0].deviceId);
    });
  }

  // nc
  const setToStart = () => {
    // nc
    // listener for media devices
    setMediaDeviceListers();

    // Set Back Button Event
    window.addEventListener('popstate', goToBack);

    let audio_and_video = { video: false, audio: false };
    if (role === "Musician") {
      audio_and_video = { video: { frameRate: { max: 15 }, height: '400px', width: '500px' }, audio: { channels: 4, autoGainControl: false, latency: 0, sampleRate: 48000, sampleSize: 16, volume: 1.0 } };

      // Ask user to Connect Camera & Mic at First Time For Musician Only
      navigator.mediaDevices.getUserMedia(audio_and_video).then((stream) => {

        getSetDevices();
        userVideoRef.current.srcObject = stream; // local
        userStream.current = stream;

        socket.emit('JOIN_JAM_ROOM', { roomId, userName: currentUser, role });

        // here new peers get added
        socket.on('USER_JOIN', (users) => {
          console.log("USER JOIN :",users);
          const temp_peers = [];
          users.forEach(({ userId, info }) => {
            let { userName, video, audio } = info;
            if (userName !== currentUser) {
              const peer = createPeer(userId, socket.id, stream);
              peer.userName = userName;
              peer.peerID = userId;
              peer.role = info.role;
              // push_unique_peer({ peerID: userId, peer, userName }
              push_unique_peer({ peer })
              temp_peers.push(peer);
              addPeerData(peer)

              setUserVideoAudio((preList) => {
                return {
                  ...preList,
                  [peer.userName]: { video, audio },
                };
              });
            }
          });
        });
        // here
        socket.on('NEW-USER-SENDING-SIGNAL', ({ signal, from, info }) => {

          console.log("New user join the jam M", from, info);

          let { userName, video, audio } = info;
          const peer = addPeer(signal, from, stream);

          peer.userName = userName;
          peer.peerID = from;
          peer.role = info.role;

          push_unique_peer({ peer })
          addPeerData(peer)

          setUserVideoAudio((preList) => {
            return {
              ...preList,
              [peer.userName]: { video, audio },
            };
          });
          // }
        });

        socket.on('SIGNAL-ACCEPTED', ({ signal, answerId }) => {
          const peerIdx = findPeer(answerId); // return the index at which the peer is holding its position in the array
          peerIdx.peer.signal(signal);
        });

        socket.on('FE-user-leave', ({ userId, userName }) => {
          const peerIdx = findPeer(userId); // return the index at which the peer is holding its position in the array
          peerIdx?.peer.destroy();
          setPeers((users) => {
            users = users.filter((user) => user.peerID !== peerIdx?.peer.peerID);
            return [...users];
          });
          peersRef.current = peersRef.current.filter(({ peer }) => peer.peerID !== userId);
        });

        socket.on('TOGGLES_MY_CAM_OR_MIC', ({ userId, switchTarget }) => {
          console.log("TOGGLES_MY_CAM_OR_MIC:");
          console.log("switchTarget:", switchTarget);
          const peerIdx = findPeer(userId); // return the index at which the peer is holding its position in the array

          const userName = peerIdx?.peer?.userName;
          setUserVideoAudio((preList) => {
            let video = preList[userName]?.video;
            let audio = preList[userName]?.audio;
            console.log("video:", video, ",audio:", audio);
            if (switchTarget === 'video') video = !video;
            else audio = !audio;
            return {
              ...preList,
              [userName]: { video, audio },
            };
          });
        });
      });
    } else {
      const stream = null;
      userStream.current = stream;
      socket.emit('JOIN_JAM_ROOM', { roomId, userName: currentUser, role });

      socket.on('USER_JOIN', (users) => {
        const temp_peers = [];
        users.forEach(({ userId, info }) => {
          let { userName, video, audio, role } = info;
          if (userName !== currentUser && role === "Musician") {
            if (currentUser !== info.userName) {
              const peer = createPeer(userId, socket.id, stream);
              peer.userName = userName;
              peer.peerID = userId;
              peer.role = role;
              push_unique_peer({ peer })
              temp_peers.push(peer);
              addPeerData(peer);

              setUserVideoAudio((preList) => {
                return {
                  ...preList,
                  [peer.userName]: { video, audio },
                };
              });
            }
          }
        });
      });

      // here

      socket.on('NEW-USER-SENDING-SIGNAL', ({ signal, from, info }) => {

        let { userName, video, audio } = info;
        const peerIdx = findPeer(from); // return the index at which the peer is holding its position in the array

        const peer = addPeer(signal, from, stream);

        peer.userName = userName;
        peer.peerID = from;
        peer.role = info.role;

        push_unique_peer({ peer })

        addPeerData(peer)

        setUserVideoAudio((preList) => {
          return {
            ...preList,
            [peer.userName]: { video, audio },
          };
        });
        // }
      });

      socket.on('SIGNAL-ACCEPTED', ({ signal, answerId }) => {
        const peerIdx = findPeer(answerId);
        peerIdx.peer.signal(signal);
      });

      socket.on('FE-user-leave', ({ userId, userName }) => {
        const peerIdx = findPeer(userId);
        peerIdx?.peer.destroy();
        setPeers((users) => {
          users = users.filter((user) => user.peerID !== peerIdx?.peer.peerID);
          return [...users];
        });
        peersRef.current = peersRef.current.filter(({ peer }) => peer.peerID !== userId);
      });

      socket.on('TOGGLES_MY_CAM_OR_MIC', ({ userId, switchTarget }) => {
        console.log("TOGGLES_MY_CAM_OR_MIC");
        const peerIdx = findPeer(userId);

        const userName = peerIdx?.peer?.userName;
        setUserVideoAudio((preList) => {

          let video = preList[userName].video;
          let audio = preList[userName].audio;
          if (switchTarget === 'video') video = !video;
          else audio = !audio;

          return {
            ...preList,
            [userName]: { video, audio },
          };

        });
      });
    }
  }

  const setMediaDeviceListers = () => {
    navigator.mediaDevices.ondevicechange = (event) => {
      // setSelectedAudioDeviceId(0)
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        let selectedAudioDevicesInList = true;
        audioDevices.map((device) => {
          if (device.deviceId !== selectedAudioDeviceId) {
            selectedAudioDevicesInList = false
          } else {
            selectedAudioDevicesInList = true
          }
        });
        if (!selectedAudioDevicesInList) setSelectedAudioDeviceId(0)
        setAudioDevices(audioDevices);
        const newVideoDevices = devices.filter((device) => device.kind === 'videoinput');
        setVideoDevices(newVideoDevices);
      });
    };
  }

  // nc
  const push_unique_peer = ({ peer }) => {
    if (peersRef.current.length > 0) {
      peersRef.current.forEach((peerData, index) => {

        if (peerData?.peer?.userName === peer?.userName) {
          console.log("replacing peer");
          peersRef.current[index] = {};
          peersRef.current.push({ peer })

        } else {
          console.log("adding new peer");
          peersRef.current.push({ peer });
        }
      }
      )
    } else {
      peersRef.current.push({ peer })
    }
  }

  const addPeerData = (peer) => {
    const peer_role = peer.role;

    if (peer_role === "Musician") {
      setPeers((prePeers) => {
        const peerIndex = prePeers.findIndex((prePeer) => prePeer.userName === peer.userName);
        if (peerIndex === -1) {
          return [...prePeers, peer]
        } else {
          prePeers[peerIndex] = peer;
          return prePeers;
        }
      })

    } else {
      setSpectatorPeers((preSpectators) => {
        const peerIndex = preSpectators.findIndex((prePeer) => prePeer.userName === peer.userName);
        if (peerIndex === -1) {
          return [...preSpectators, peer]
        } else {
          preSpectators[peerIndex] = peer;
          return preSpectators
        }
      })
    }

  }

  const setMediaBitrate = (sdp, mediaType, bitrate) => {
    const sdpLines = sdp.split('\n');
    let mediaLineIndex = -1;
    const mediaLine = 'm=${mediaType}';
    let bitrateLineIndex = -1;
    const bitrateLine = 'b=AS:${bitrate}';
    mediaLineIndex = sdpLines.findIndex(line => line.startsWith(mediaLine));

    // If we find a line matching “m={mediaType}”
    if (mediaLineIndex > -1 && mediaLineIndex < sdpLines.length) {
      // Skip the media line
      bitrateLineIndex = mediaLineIndex + 1;

      // Skip both i=* and c=* lines (bandwidths limiters have to come afterwards)
      while (sdpLines[bitrateLineIndex].startsWith('i=') || sdpLines[bitrateLineIndex].startsWith('c=')) {
        bitrateLineIndex += 1;
      }

      if (sdpLines[bitrateLineIndex].startsWith('b=')) {
        // If the next line is a b=* line, replace it with our new bandwidth
        sdpLines[bitrateLineIndex] = bitrateLine;
      } else {
        // Otherwise insert a new bitrate line.
        sdpLines.splice(bitrateLineIndex, 0, bitrateLine);
      }
    }
    return sdpLines.join('\n');
  };


  const iceServers = [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:stun1.l.google.com:19302' },
    { urls: 'stun:stun2.l.google.com:19302' },
    { urls: 'stun:stun3.l.google.com:19302' },
    { urls: 'stun:stun4.l.google.com:19302' },
    // { urls: 'stun:global.stun.twilio.com:3478?transport=udp' },
    { urls: 'stun:stun.voipstunt.com' },
    { urls: 'stun:stun.voipbuster.com' },
    { urls: 'stun:stun.voiparound.com' },
    { urls: 'stun:stun.stunprotocol.org' },
    // { urls: 'turn:global.turn.twilio.com:3478?transport=tcp' },
    {
      urls: "stun:openrelay.metered.ca:80",
    },
    {
      urls: 'turn:openrelay.metered.ca:80',
      username: 'openrelayproject',
      credential: 'openrelayproject'
    },
    {
      urls: 'turn:openrelay.metered.ca:80?transport=tcp',
      username: 'openrelayproject',
      credential: 'openrelayproject'
    },
    {
      urls: 'turn:openrelay.metered.ca:443',
      username: 'openrelayproject',
      credential: 'openrelayproject'
    },
    {
      urls: 'turn:openrelay.metered.ca:443?transport=tcp',
      username: 'openrelayproject',
      credential: 'openrelayproject'
    },
    {
      urls: "stun:numb.viagenie.ca",
      username: "pasaseh@ether123.net",
      credential: "12345678"
    },
    // { urls: 'turn:numb.viagenie.ca' },
    {
      urls: "turn:numb.viagenie.ca",
      username: "pasaseh@ether123.net",
      credential: "12345678"
    },
    {
      urls: "stun:relay.metered.ca:80",
    },
    {
      urls: "turn:relay.metered.ca:80",
      username: "732fe4f58d6bcbebd9d44af0",
      credential: "1/oPEn1GdLYRX3ft",
    },
    {
      urls: "turn:relay.metered.ca:443",
      username: "732fe4f58d6bcbebd9d44af0",
      credential: "1/oPEn1GdLYRX3ft",
    },
    {
      urls: "turn:relay.metered.ca:443?transport=tcp",
      username: "732fe4f58d6bcbebd9d44af0",
      credential: "1/oPEn1GdLYRX3ft",
    },
    // new ice candidates for turn servers: added 13/2/23
    // {
    //   urls: ["stun:eu-turn4.xirsys.com"]
    // },
    // {
    //   username: "ml0jh0qMKZKd9P_9C0UIBY2G0nSQMCFBUXGlk6IXDJf8G2uiCymg9WwbEJTMwVeiAAAAAF2__hNSaW5vbGVl",
    //   credential: "4dd454a6-feee-11e9-b185-6adcafebbb45",
    //   urls: [
    //     "turn:eu-turn4.xirsys.com:80?transport=udp",
    //     "turn:eu-turn4.xirsys.com:3478?transport=tcp"
    //   ]
    // }
  ]

  // If new user came in this method will call
  function createPeer(userId, caller, stream) {

    const peer = new Peer({
      initiator: true,
      trickle: false,
      iceTransportPolicy: 'relay',
      sdpTransform: (sdp) => {
        return setMediaBitrate(sdp, 'audio', 30000) + "a=fmtp:100 x-google-min-bitrate=30000\r\n";
      },
      stream,
      iceRestart: true,
      config: {
        iceServers: iceServers,
        sdpSemantics: 'unified-plan'
      },
      codecs: [
        { name: "opus", clockRate: 5000, channels: 2 },
        { name: "vp9", clockRate: 5000, channels: 2 },
        { name: "vp8", clockRate: 5000, channels: 2 },
        { name: "h264", clockRate: 90000, channels: 2, packetizationMode: 1 }
      ]
    });

    peer.on('signal', (signal) => {
      console.log("signal 1");

      socket.emit('SEND-SIGNAL-TO-OLD-USERS', {
        userToCall: userId,
        from: caller,
        signal,
      });
    });

    peer.on('connect', () => {
      if (role === "Musician" && peer.role === "Spectator") {
        message.success(`${peer.userName} spectator joined`, 2.5);
      }
    })

    peer.on('error', e => {
      console.log('CP Error : ', e);
      removeSpectators(peer)
      removePeer(peer)
    })

    peer.on('disconnect', () => {
      console.log("disconnect initiator");
    });

    peer.on('close', () => {
      if (role === "Musician" && peer.role === "Spectator") {
        message.error(`${peer.userName} spectator leave`, 3);
      }
      removeSpectators(peer)
      removePeer(peer)

      createPeer()
    })
    return peer;
  }

  function addPeer(incomingSignal, callerId, stream) {
    console.log("addPeer");
    const peer = new Peer({
      // wrtc:{'RTCPeerConnection':window.RTCPeerConnection},
      initiator: false,
      trickle: true,
      sdpTransform: (sdp) => { return setMediaBitrate(sdp, 'audio', 30000) + "a=fmtp:100 x-google-min-bitrate=30000\r\n" },
      stream,
      iceRestart: true,
      config: {
        iceServers: iceServers
      },
      codecs: [
        { name: "opus", clockRate: 5000, channels: 2 },
        { name: "vp9" },
        { name: "vp8" },
        { name: "h264", packetizationMode: 1 }
      ]
    });

    peer.on('signal', (signal) => {
      socket.emit('ACCEPT-SIGNAL', { signal, to: callerId });
    });

    peer.on('error', e => {
      console.log('AP Error : ', e);
      removePeer(peer)
    })

    peer.on('disconnect', () => {
      console.log(peer.userName, " disconnect");
    });

    peer.on('close', () => {
      if (role === "Musician" && peer.role === "Spectator") {
        message.error(`${peer.userName} spectator leave`, 3);
      }
      removeSpectators(peer)
      removePeer(peer)
    })

    peer.on('connect', () => {
      if (role === "Musician" && peer.role === "Spectator") {
        message.success(`${peer.userName} spectator joined`, 2.5);
      }
    })
    peer.signal(incomingSignal);
    return peer;
  }

  const removeSpectators = (peer) => {
    setSpectatorPeers((pSpect) => {
      let spectUsers = pSpect.filter((user) => user.userName !== peer.userName);
      return spectUsers;
    })
  }

  function removePeer(rpeer) {
    rpeer.destroy()
    setPeers((users) => {
      users = users.filter((user) => user.peerID !== rpeer.peerID);
      return [...users];
    });
    peersRef.current = peersRef.current.filter(({ peer }) => peer?.userName !== rpeer?.userName);
  }

  // Its return the index at which the peer is holding its position in the array
  function findPeer(id) {
    return peersRef.current.find(({ peer }) => peer?.peerID === id);
  }


  // need the seperate 
  const stopAudioStream = () => { }

  // Open Chat
  const clickChat = (e) => {
    e.stopPropagation();
    setDisplayChat(!displayChat);
  };

  // BackButton
  const goToBack = (e) => {
    e.preventDefault();
    socket.emit('BE-leave-room', { roomId, leaver: currentUser });
    sessionStorage.removeItem('user');
    sessionStorage.clear();
    window.location.href = '/';
  };

  const toggleCameraAudio = (e) => {
    const target = e // .target.getAttribute('data-switch');

    setUserVideoAudio((preList) => {
      let videoSwitch = preList['localUser'].video;
      let audioSwitch = preList['localUser'].audio;

      if (target === 'video') {
        console.log("switching video");
        const userVideoTrack = userVideoRef.current.srcObject.getVideoTracks()[0];

        videoSwitch = !videoSwitch;
        if (userVideoTrack) { userVideoTrack.enabled = videoSwitch }
        socket.emit('TOGGLE_CAMERA_MIC', { roomId, switchTarget: target }, () => {
          switchVideoStream(videoSwitch)
        });
      } else {
        console.log("switching audio");

        const userAudioTrack = userVideoRef.current.srcObject.getAudioTracks()[0];
        audioSwitch = !audioSwitch;

        if (userAudioTrack) {
          console.log("userAudioTrack:true");
          userAudioTrack.enabled = audioSwitch;
        } else {
          console.log("userAudioTrack:false");
          userStream.current.getAudioTracks()[0].enabled = audioSwitch;
        }
        socket.emit('TOGGLE_CAMERA_MIC', { roomId, switchTarget: target }, () => { });
      }

      return {
        ...preList,
        localUser: { video: videoSwitch, audio: audioSwitch },
      };
    });
  };

  const SendTimestampMetronome = (metroData) => {
    socket.emit('BE-metronome', { roomId, metroData });
  }

  const clickScreenSharing = () => {
    if (!screenShare) {
      navigator.mediaDevices.getDisplayMedia({ cursor: true }).then((stream) => {
        const screenTrack = stream.getTracks()[0];

        peersRef.current.forEach(({ peer }) => {
          // replaceTrack (oldTrack, newTrack, oldStream);
          peer.replaceTrack(peer.streams[0].getTracks().find((track) => track.kind === 'video'), screenTrack, userStream.current);
        });

        // Listen click end
        screenTrack.onended = () => {
          peersRef.current.forEach(({ peer }) => {
            peer.replaceTrack(screenTrack, peer.streams[0].getTracks().find((track) => track.kind === 'video'), userStream.current);
          });
          userVideoRef.current.srcObject = userStream.current;
          setScreenShare(false);
        };

        userVideoRef.current.srcObject = stream;
        screenTrackRef.current = screenTrack;
        setScreenShare(true);
      });
    } else {
      screenTrackRef.current.onended();
    }
  };

  const expandScreen = (e) => {
    const elem = e.target;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const clickBackground = () => {
    if (!showVideoDevices) return;

    setShowVideoDevices(false);
  };

  const clickCameraDevice = (event) => {
    if (event && event.target && event.target.dataset && event.target.dataset.value) {
      const deviceId = event.target.dataset.value;
      const enabledAudio = userVideoRef.current.srcObject.getAudioTracks()[0].enabled;

      navigator.mediaDevices
        .getUserMedia({ video: { deviceId }, audio: enabledAudio })
        .then((stream) => {
          const newStreamTrack = stream.getTracks().find((track) => track.kind === 'video');
          const oldStreamTrack = userStream.current
            .getTracks()
            .find((track) => track.kind === 'video');

          userStream.current.removeTrack(oldStreamTrack);
          userStream.current.addTrack(newStreamTrack);

          peersRef.current.forEach(({ peer }) => {
            // replaceTrack (oldTrack, newTrack, oldStream);
            peer.replaceTrack(
              oldStreamTrack,
              newStreamTrack,
              userStream.current
            );
          });
        });
    }
  };

  // nc
  const switchAudioSource = (audioDeviceId) => {

    setSelectedAudioDeviceId(audioDeviceId)

    navigator.mediaDevices.getUserMedia({ audio: { 'deviceId': audioDeviceId, enabledAudio: true } })
      .then((stream) => {
        const newStreamTrack = stream.getTracks().find((track) => track.kind === 'audio');

        const oldStreamTrack = userStream.current.getTracks().find((track) => track.kind === 'audio');

        userStream.current.removeTrack(oldStreamTrack);
        userStream.current.addTrack(newStreamTrack);

        peersRef.current.forEach(({ peer }) => {
          peer.replaceTrack(
            oldStreamTrack,
            newStreamTrack,
            userStream.current
          );

        });
      }).catch((error) => {
        console.log("Error while switching the audio source,", error)
      });
  }

  // nc
  const switchVideoStream = async (cameraState) => {

    console.log("cameraState:", cameraState);

    console.log("peersRef.current :", peersRef.current);

    // here we are stopping camera stream & turning off camera light

    if (peersRef.current.length > 0 && !cameraState) {


      // const blankTrack = await navigator.mediaDevices.getUserMedia({ video: { height: 0, width: 0 } }).then(stream => stream.getVideoTracks()[0]);


      const constraints = { video: true };
      navigator.mediaDevices.getUserMedia(constraints)
        .then((currentStream) => {

          peersRef.current.forEach((participant) => {
            console.log("removing video track ");

            const stream = participant.peer.streams[0]
            // stream.getVideoTracks().forEach(track => {
            //   console.log("stoping video");
            //   track.stop()
            // })

            // currentStream.getVideoTracks().forEach(track => {
            //   console.log("stoping video");
            //   track.stop()
            // })

            // const videoTrack = stream.getTracks().find((track) => track.kind === 'video');
            const videoTrack = stream.getVideoTracks()[0]
            // videoTrack.stop()
            //
            const audioTrack = stream.getAudioTracks()[0]

            // participant.peer.removeStream(stream)
            // participant.peer.addStream(new MediaStream([audioTrack]))

            if (videoTrack && !participant.peer.destroyed) {
              participant.peer.replaceTrack(
                videoTrack,
                null,
                stream
              )
            }
          })
        })
    } else {
      console.log("adding video track ");

      const constraints = { video: true };
      navigator.mediaDevices.getUserMedia(constraints)
        .then((currentStream) => {
          // userStream.current = currentStream
          userVideoRef.current.srcObject = currentStream

          if (peersRef.current.length > 0) {
            peersRef.current.forEach((participant) => {

              const stream = participant.peer?.streams[0];
              const videoTrack = currentStream.getTracks().find((track) => track.kind === 'video')


              !participant.peer.destroyed && participant.peer.replaceTrack(
                stream.getTracks().find((track) => track.kind === 'video'),
                videoTrack,
                stream
              )
            })
          }
        })
    }
  }


  const setBlackOut = () => {
    let silence = () => {
      let ctx = new AudioContext(), oscillator = ctx.createOscillator();
      let dst = oscillator.connect(ctx.createMediaStreamDestination());
      oscillator.start();
      return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
    }

    let black = ({ width = 640, height = 480 } = {}) => {
      let canvas = Object.assign(document.createElement("canvas"), { width, height });
      canvas.getContext('2d').fillRect(0, 0, width, height);
      let stream = canvas.captureStream();
      return Object.assign(stream.getVideoTracks()[0], { enabled: false });
    }

    let blackSilence = (...args) => new MediaStream([black(...args), silence()]);

    userVideoRef.srcObject = blackSilence({ width: 640, height: 480 });
  }




  // It returns the number for responsiveness of users screens
  const responsive = (i) => {
    let count = 1;
    let users = peers.length + 1;
    if (role === "Musician") {
      if (users === 1) {
        count = 2;
      } else if (users === 2) {
        count = 1;
      } else if (users === 3) {
        count = i === 3 ? 2 : 1;
      } else {
        count = 1;
      }
      return count;
    } else {
      let users = peers.length;
      if (users === 2) { count = 1 }
      else if (users === 3) {
        count = i === 3 ? 2 : 1;
      } else if (users === 4) {
        count = 1
      }
      return users === 1 ? 2 : count
    }
  };

  // Return the Main View
  return (
    <>
      <Layout className='root' style={{ height: '100vh' }}>
        <Header className='header'>
          <div className="logo">
            <img src={"https://jam.choira.io/choira.svg"} alt="Choira logo" style={{ paddingBottom: "50px" }} />
          </div>
          {
            role === "Musician" ?

              <div className='spectator'>
                Spectators : {spectatorPeers?.length}
              </div> : null
          }
        </Header>
        <Content className='main_container'>

          <div className='VidContainer'>

            <Row gutter={[5, 5]}>
              {peers?.length === 0 && role === "Spectator" ? <div ref={connectingRef} className='nousers'>Connecting...</div> : null}
              {role === "Musician" ?
                <Col
                  className='myvideocolumn'
                  direction="horizontal"
                  align="center"
                  style={{
                    justifyContent: "center",
                    height: peers?.length > 1 ? "25%" : "50%",
                  }}
                  xs={12 * responsive(1)}
                  sm={12 * responsive(1)}
                  md={12 * responsive(1)}
                  lg={12 * responsive(1)}
                  xl={12 * responsive(1)}>
                  <Badge.Ribbon text="(Me) ♛" placement="start" color="gold">
                    <video
                      className='myvideo'
                      playsInline
                      autoPlay
                      onClick={expandScreen}
                      ref={userVideoRef}
                      style={{
                        alignContent: "center",
                        width: peers?.length > 1 ? "40%" : (peers.length === 0 ? "44%" : "100%"),
                      }}
                      muted
                    // playInline
                    ></video>
                  </Badge.Ribbon>
                  {/* <AudioVisu /> */}
                </Col> : null}
              {
                Object.keys(peers).length > 0 &&
                peers.map((peer, index, arr) => {
                  if (peer?.role === "Musician") {
                    return <Col
                      className='peervideocolumn'
                      key={peer.peerID} direction="horizontal"
                      align="center"
                      style={{
                        justifyContent: "center",
                      }}
                      xs={12 * responsive(index + startCounter)}
                      sm={12 * responsive(index + startCounter)}
                      md={12 * responsive(index + startCounter)}
                      lg={12 * responsive(index + startCounter)}
                      xl={12 * responsive(index + startCounter)}>

                      <Badge.Ribbon text={peer?.userName} placement="start" color="blue" id="userBadge">
                        <VideoCard peer={peer} number={arr.length} index={index} role={role} userVideoAudio={userVideoAudio} />
                        <Badge className='rtt' count={Object.keys(statsReport).length ? `RTT ${statsReport[peer.userName]?.rtt * 1000} ms` : 'RTT:N/A'} style={{ color: "green", border: 'none', backgroundColor: "transparent", zIndex: 999 }} size="small">
                        </Badge>
                      </Badge.Ribbon>

                    </Col>
                  }
                }
                )
              }

            </Row>

          </div>

        </Content>
        <Footer style={{ 'backgroundColor': 'rgba(31,37, 58, 1)' }}>
          <BottomBar
            clickScreenSharing={clickScreenSharing}
            clickChat={clickChat}
            clickCameraDevice={clickCameraDevice}
            goToBack={goToBack}
            toggleCameraAudio={toggleCameraAudio}
            userVideoAudio={userVideoAudio['localUser']}
            screenShare={screenShare}
            videoDevices={videoDevices}
            showVideoDevices={showVideoDevices}
            setShowVideoDevices={setShowVideoDevices}
            audioDevices={audioDevices}
            switchAudioSource={switchAudioSource}
            SendTimestampMetronome={SendTimestampMetronome}
            role={role}
          />
        </Footer>
      </Layout>

    </>



  );
};


export default Meet;