import React from "react";

import customstyle from "./layout.module.css";
function Button({ name, icon, onClick, style, type }) {
  return (
    <>
      <button
        className={customstyle.reuseablebtn}
        onClick={onClick}
        style={style}
        type={type}
      >
        <div>{icon}</div>
        <div>{name}</div>
      </button>
    </>
  );
}

export default Button;
