import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import './videocard.css'

const VideoCard = (props) => {

  useEffect(() => {

    console.log("Video Card Created=======");
  
    return () => {
      console.log("VideoCard Deleted");
    }
  }, [])
  

  let ustream = ""
  const ref = useRef();
  const aref = useRef();

  const peer = props.peer;
  const totalPeer = props.number;
  const index = props.index;
  const role = props.role;
  const userVideoAudio = props.userVideoAudio;

  const userName = peer?.userName;

  const video = userVideoAudio[userName]?.video
  if (video !== undefined && !video) {
    if (ref.current) ref.current.src = "";
  } else {
    if (ustream !== "") {
      ref.current.srcObject = ustream;
    }
  }

  useEffect(() => {
    peer.on('stream', (stream) => {
      console.log("stream received !");
      ustream = stream
      ref.current.srcObject = stream;
      aref.current.srcObject = stream;
    });
  }, [peer]);

  return (
    // <div className='peerdiv'>
    <div>
      <video
        className='PeerContainer'
        playsInline
        autoPlay
        // onLoadStart={(e)=>{e.target.volume=0.0}}
        ref={ref}
        style={{
          width: role === "Musician" ? (index === 1 && totalPeer == 2 ? "22%" : (totalPeer === 1 ? "100%" : "40%")) : (totalPeer == 2 ? "100%" : (totalPeer === 1 ? "44%" : "27%"))
        }}
      />
      <audio autoPlay ref={aref}/>
    </div>
  );
};

export default VideoCard;
