import React from 'react'
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

export const AudioVisu = () => {
    
  return (
    <div>
      {/* <AudioReactRecorder state={recordState} onStop={this.onStop} /> */}
    </div>
  )
}
