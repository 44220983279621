import React, { useEffect, useState } from "react";
import style from "./Main2.module.css";
import o4 from "../../assets/images/o4.png";
import logo from "../../assets/images/logo-choira.svg";
import laptop from "../../assets/images/laptop.png";
import music from "../../assets/videos/video2.mp4";
import Button from "../../layout/Button";
import sockets from "../../socket";
import { FaBars } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { FaAngleDown } from "react-icons/fa";

// let fdata = {};
let isjoinclicked = false;

function Main(props) {
  const [formData, setFormData] = useState({
    roomName: "",
    userName: "",
    role: "",
  });

  useEffect(() => {
    console.log(formData);
    // fdata = formData;
  }, [formData]);

  useEffect(() => {
    // const stateData = props.location.state;
    // const _ = stateData
    //   ? form.setFieldsValue({
    //       roomName: stateData.roomId,
    //       userName: "",
    //       role: "",
    //     })
    //   : null;

    sockets.on("USER_EXIST_STATUS", ({ error, error_msg }) => {
      if (!error && isjoinclicked) {
        console.log(formData);
        const { roomName, userName, role } = formData;
        sessionStorage.setItem("user", userName);
        sessionStorage.setItem("role", role);
        if (roomName) {
          props.history.push(`/jam/${roomName}`);
        }
      } else {
        // setErr(error);
        // setErrMsg(error_msg);
        // alert("error_msg");
      }
    });
    sockets.on("connect", () => {});
  }, [props.history, formData]);

  const [combinedClasses, setCombinedClasses] = useState(`${style.list}`);
  const [navLogo, setNavLogo] = useState(false);
  const smallNav = () => {
    // Check if "smalllist" class is present in the current state
    if (combinedClasses.includes(`${style.smalllist}`)) {
      setNavLogo(false);

      // If present, remove "smalllist"
      setCombinedClasses((prevClasses) =>
        prevClasses.replace(`${style.smalllist}`, "")
      );
    } else {
      // If not present, add "smalllist"
      setCombinedClasses(
        (prevClasses) => prevClasses + " " + `${style.smalllist}`
      );
      setNavLogo(true);
    }
  };

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const clickJoin = (e) => {
    isjoinclicked = true;
    e.preventDefault();
    const { roomName, userName, role } = formData;
    if (!roomName || !userName || !role) {
      alert("Enter Room Name or User Name or Role");
    } else {
      sockets.emit("CHECK_USER_EXIST", {
        roomId: roomName,
        userName: userName,
        role: role,
      });
      // alert("done");
    }
  };

  const reset = (e) => {
    e.preventDefault();
    setFormData({
      roomName: "",
      userName: "",
      role: "",
    });
  };
  const fill = (e) => {
    e.preventDefault();
    setFormData({
      roomName: "Mark",
      userName: "KET",
      role: "Musician",
    });
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  return (
    <div className={style.mainDiv}>
      <div className={style.paddingdiv}></div>
      <div className={style.navbar}>
        <div>
          <img src={logo} alt="Choira Logo" style={{ cursor: "pointer" }} />
        </div>
        <div className={combinedClasses}>
          {/* {console.log(combinedClasses)} */}
          <a href="https://choira.in/" target="_blank">
            Home
          </a>
          <a href="https://studio.choira.in/" target="_blank">
            Studio
          </a>
          <a
            style={{ color: "#ffc701" }}
            //  href="https://jam.choira.io/"
            target="_blank"
          >
            Jam
          </a>
          <a href="https://ai.choira.in/" target="_blank">
            AI Music Gen
          </a>
          <a href="" target="_blank">
            Signin
          </a>

          <img
            className={style.o4}
            style={{ cursor: "pointer" }}
            src={o4}
            alt=""
            onClick={smallNav}
          />
        </div>
        <div className={style.testlogo}>
          {navLogo && (
            <img
              className={style.o5}
              style={{ cursor: "pointer" }}
              src={o4}
              alt=""
              onClick={smallNav}
            />
          )}
        </div>
      </div>

      {/* mobile navbar start here------------------- */}
      <div className={style.mobileNavbar}>
        <div>
          <img src={logo} alt="Choira Logo" style={{ cursor: "pointer" }} />
        </div>
        <div>
          <FaBars
            onClick={() => {
              setSidebarVisible((prevState) => !prevState); // Toggle the state
            }}
          />
        </div>
      </div>
      {/* mobile navbar end here------------------- */}

      {/* sidebar-----------=-- */}
      <div
        className={
          `${style.lpSidebar}` +
          " " +
          `${sidebarVisible ? `${style.lpSidebarAfter}` : ""}`
        }
      >
        <div>
          <img src={logo} alt="Choira Logo" style={{ cursor: "pointer" }} />
          <ImCross
            style={{ marginTop: "3%" }}
            onClick={() => {
              setSidebarVisible((prevState) => !prevState); // Toggle the state
            }}
          />
        </div>
        <div>
          <div>Home</div>
          <div>Studio</div>
          <div style={{ color: "#ffc701" }}>Jam</div>
          <div
          //  onClick={gotoDashboard}
          >
            AI Music Gen
          </div>
          <div>Signin</div>
        </div>
      </div>

      {/* sidebar end -------------------------- */}

      <div className={style.mainContent}>
        <div className={style.laptopDiv}>
          <div>
            <p>
              Fast<span>,</span> Reliable <span>&</span> Secure
            </p>
          </div>
          <div>
            <p>
              Jam Anytime, Anywhere with <span>Choira Jam</span>
            </p>
          </div>
          <div>
            <div>
              <img src={laptop} alt="" />
            </div>
            <div className={style.videoDiv}>
              <video src={music} autoPlay muted loop></video>
            </div>
          </div>
        </div>
        <div className={style.formDiv}>
          <form action="">
            <div className={style.custominput}>
              <label htmlFor="Room">Room Name</label>
              <input
                type="text"
                id="Room"
                placeholder="Room Name"
                onChange={(event) => {
                  updateData(event);
                }}
                value={formData.roomName}
                name="roomName"
              />
            </div>
            <div className={style.custominput}>
              <label htmlFor="User">User Name</label>
              <input
                type="text"
                id="User"
                placeholder="User Name"
                onChange={(event) => {
                  updateData(event);
                }}
                name="userName"
                value={formData.userName}
              />
            </div>

            <div className={style.custominput}>
              <label htmlFor="User">Joining as</label>
              <div className={style.customSelect}>
                <select
                  name="role"
                  id=""
                  value={formData.role} // Add value attribute here
                  onChange={(event) => {
                    updateData(event);
                  }}
                >
                  <option value="" disabled>
                    Joining as
                  </option>
                  <option value="Musician">Musician</option>
                  <option value="Spectator">Spectator</option>
                </select>
                <FaAngleDown className={style.selectArrow} />
              </div>
            </div>
            <div className={style.formbtns}>
              <Button
                name={"Create/Join"}
                style={{ height: "60%", width: "40%", fontWeight: "600" }}
                onClick={(e) => {
                  clickJoin(e);
                }}
              />
              <Button
                name={"Reset"}
                style={{
                  height: "60%",
                  width: "20%",
                  backgroundColor: "#FFF9E3 ",
                  fontWeight: "600",
                }}
                onClick={(e) => {
                  reset(e);
                }}
              />
              <span
                className={style.fillFormBtn}
                onClick={(e) => {
                  fill(e);
                }}
              >
                Fill Form
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Main;